import * as React from 'react';

tipo Máquina<s> = { [k: string]: { [k: string]: S } };
type MachineState<t> = keyof T;
tipo MachineEvent<t> = clave de la unión a la intersección<t[keyof T]="">;

// 🤯 https://fettblog.eu/typescript-union-to-intersection/
tipo UnionToIntersection<t> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any
  ? R
  : nunca;

exportar función useStateMachine<m>(
  estadoinicial: MachineState<m>,
  máquina: M & Machine<machinestate<m>>
) {
  return React.useReducer((state: MachineState<m>evento: MachineEvent<m>): MachineState<m> => {
    const nextState = (machine[state] as any)[event];
    return nextState ?? state;
  }, initialState);
}
</m></m></m></machinestate<m></m></m></t></t[keyof></t></t></s>